<template>
  <div class="row p-6">
    <div class="col-12">
      <chart-report :item="item"
                    v-if="reportId != 5 && item.rows.length > 0"/>
    </div>
    <div class="col-12">
      <table-report :item="item"
                    :funcs="funcs"
                    :columnPeriod="columnPeriod"
                    :columnTypeReport="columnTypeReport"
                    v-if="reportId != 5 && item.type == 1" />
      <table-data-customer  :item="item"
                            :funcs="funcs"
                            :columnTypeReport="columnTypeReport"
                            v-if="reportId != 5 && item.type == 2 && report.type_data == 1" />
      <table-data-sale  :item="item"
                        :funcs="funcs"
                        :columnTypeReport="columnTypeReport"
                        v-if="reportId != 5 && item.type == 2 && report.type_data == 2" />
      <table-data-product :item="item"
                          :funcs="funcs"
                          :columnTypeReport="columnTypeReport"
                          v-if="reportId != 5 && item.type == 2 && report.type_data == 3" />
      <table-data-store :item="item"
                        :funcs="funcs"
                        :columnTypeReport="columnTypeReport"
                        v-if="reportId != 5 && item.type == 2 && report.type_data == 4" />
      <table-data-supplier  :item="item"
                            :funcs="funcs"
                            :columnTypeReport="columnTypeReport"
                            v-if="reportId != 5 && item.type == 2 && report.type_data == 5" />
      <table-data-location  :item="item"
                            :funcs="funcs"
                            :columnTypeReport="columnTypeReport"
                            v-if="reportId != 5 && item.type == 2 && report.type_data == 6" />
      <table-data-current-payment :item="item"
                                  :funcs="funcs"
                                  :columnTypeReport="columnTypeReport"
                                  v-if="reportId != 5 && item.type == 3 && report.data_current == 1" />
    </div>
    <div class="col-12">
      <table-order-report :item="item"
                          :funcs="funcs"
                          :columnPeriod="columnPeriod"
                          v-if="reportId == 5 && item.type == 1" />
    </div>
  </div>
</template>
<script>
  import chartReport from './chart';
  import tableReport from './table.report';
  import tableOrderReport from './table.order.report';
  import tableDataCustomer from './table.data.customer';
  import tableDataSale from './table.data.sale';
  import tableDataProduct from './table.data.product';
  import tableDataStore from './table.data.store';
  import tableDataSupplier from './table.data.supplier';
  import tableDataLocation from './table.data.location';
  import tableDataCurrentPayment from './table.dataCurrent.payment';
  export default {
    name: 'report-view-detail-report',
    props: [
      'item',
      'funcs',
      'columnPeriod',
      'columnTypeReport',
      'reportId',
      'data',
      'report'
    ],
    components: {
      chartReport,
      tableReport,
      tableOrderReport,
      tableDataCustomer,
      tableDataSale,
      tableDataProduct,
      tableDataStore,
      tableDataSupplier,
      tableDataLocation,
      tableDataCurrentPayment
    }
  };
</script>

