<template lang="">
  <div>
    <div class="card-body bg-white">
      <div class="pl-lg-4">
        <div class="row hidden_show_print">
          <div class="column">
            <div class="row">
              <div class="col-sm-5 mb-2"><strong>ประเภทรายงาน :</strong></div>
              <div class="col-sm-7">{{ item.type_report_name }}</div>
              <div class="col-sm-5 mb-2"><strong>แบ่งแยกข้อมูลตามประเภท :</strong></div>
              <div class="col-sm-7">{{ item.type_name }}</div>

              <div class="col-sm-5 mb-2" v-if="report.type == 1">
                <strong>แบ่งข้อมูลตามประเภทเวลา :</strong>
              </div>
              <div class="col-sm-7" v-if="report.type == 1">{{ item.type_time_name }}</div>
              <div class="col-sm-5 mb-2" v-if="report.type_report == 1 && report.type == 2">
                <strong>แยกประเภทตามข้อมูล :</strong>
              </div>
              <div class="col-sm-7" v-if="report.type_report == 1 && report.type == 2">{{ item.type_data_name }}</div>
              <div class="col-sm-5 mb-2" v-if="report.type_report == 3 && report.type == 3">
                <strong>แบ่งตามข้อมูลปัจจุบัน :</strong>
              </div>
              <div class="col-sm-7" v-if="report.type_report == 3 && report.type == 3">{{ item.data_current_name }}
              </div>
              <div class="col-sm-5 mb-2" v-if="report.type_report == 5">
                <strong>รหัสการสั่งซื้อ :</strong>
              </div>
              <div class="col-sm-7" v-if="report.type_report == 5">{{ item.order_code }}</div>
            </div>
          </div>
          <div class="column">
            <div class="row">
              <div class="col-sm-5 mb-2"><strong>ตั้งแต่ :</strong></div>
              <div class="col-sm-7">{{ item.start }}</div>
              <div class="col-sm-5 mb-2"><strong>ถึง :</strong></div>
              <div class="col-sm-7">{{ item.end }}</div>

              <div class="col-sm-5 mb-2" v-if="report.type_report == 1"><strong>ร้านค้า :</strong></div>
              <div class="col-sm-7" v-if="report.type_report == 1">{{ item.store_name }}</div>

              <div class="col-sm-5 mb-2" v-if="report.type_report == 4"><strong>พนักงานขาย :</strong></div>
              <div class="col-sm-7" v-if="report.type_report == 4">{{ item.sale_name }}</div>

            </div>
          </div>
        </div>
        <div class="row no_print">
          <div class="col-md-4">
            <div class="mb-4">
              <label class="form-label">ประเภทรายงาน</label>
              <select class="form-control" v-model="report.type_report" @change="changeTypeReport(report.type_report)">
                <option v-for="titem in types" :value="titem.id">{{ titem.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <div class="mb-4">
              <label class="form-label">แบ่งแยกข้อมูลตามประเภท</label>
              <b-form-radio-group v-model="report.type" :options="options" class="mt-2" name="type" text-field="text" size="sm"></b-form-radio-group>
            </div>
          </div>
          <div class="col-md-4" v-if="report.type == 1">
            <div class="mb-4">
              <label class="form-label">แบ่งข้อมูลตามประเภทเวลา</label>
              <select class="form-control" v-model="report.type_time" @change="changeTypeTime(report.type_time)" >
                <option v-for="time in type_times" :key="time.id" :value="time.id" >{{ time.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-4" v-if="report.type_report == 1 && report.type == 2" >
              <label class="form-label">แยกประเภทตามข้อมูล</label>
              <select class="form-control" v-model="report.type_data" @change="changeTypeData(report.type_data)" >
                <option
                  v-for="data in type_data"
                  :key="data.id"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
            </div>
            <div class="mb-4" v-if="report.type_report == 3 && report.type == 3" >
              <label class="form-label">แบ่งตามข้อมูลปัจจุบัน</label>
              <select
                class="form-control"
                v-model="report.data_current"
                @change="changeDataCurrent(report.data_current)"
              >
                <option
                  v-for="data in data_current"
                  :key="data.id"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <hr class="m-3" />
            <h6 class="heading-small text-muted mb-4 text-capitalize">
              Filter
            </h6>
          </div>
          <div class="col-md-4">
            <div class="mb-4">
              <label class="form-label">ตั้งแต่</label>
              <date-picker :format="'DD/MM/YYYY'" v-model="report.start" class="w-100" input-class="form-control" :disabled-date="disableStartDate" :append-to-body="false"></date-picker>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-4">
              <label class="form-label">ถึง</label>
              <date-picker :format="'DD/MM/YYYY'" v-model="report.end" class="w-100" input-class="form-control" :disabled-date="disabledEndDate":append-to-body="false"></date-picker>
            </div>
          </div>
          <div class="col-md-4 mb-4" v-if="report.type_report == 1">
            <label class="form-label">ร้านค้า</label>
            <select class="form-control" v-model="report.store_id" @change="changeStore(report.store_id)">
              <option v-for="data in stores" :key="data.id" :value="data.id">{{ data.text }}</option>
            </select>
          </div>
          <div class="col-md-4 mb-4" v-if="report.type_report == 1">
            <label class="form-label">รหัส</label>
            <input class="form-control" placeholder="รหัสสั่งซื้อ">
          </div>
          <div class="col-md-4 mb-4" v-if="report.type_report == 1">
            <label class="form-label">สถานที่ตั้ง</label>
            <select class="form-control" v-model="report.sale_id" @change="changeSale(report.sale_id)" >
              <option v-for="data in sales" :value="data.id">{{ data.text }}</option>
            </select>
          </div>

          
          <div class="col-12 no_print">
            <hr />
            <div class="float-right d-flex">
              <b-button type="button" variant="primary" class="style_btn" @click="clickAgree()">ตกลง</b-button>
              <b-button type="button" variant="warning" class="style_btn" @click="clickClear()" > ล้าง</b-button >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="show">
      <div class="card-body">
        <div class="row pb-5 no_print">
          <div class="text-right col-12">
            <!-- <b-button type="button" variant="success" class="style_btn"><i class="far fa-file-excel"></i> Excel</b-button> -->
            <vue-excel-xlsx
              :data="data"
              :columns="columns"
              :filename="filename"
              :sheetname="'sheetname'"
              class="style_btn btn btn-success"
            >
              <i class="far fa-file-excel"></i> Excel
            </vue-excel-xlsx>
            <b-button
              type="button"
              variant="warning"
              class="style_btn"
              @click="clickPrint()"
              ><i class="fas fa-print"></i> พิมพ์</b-button
            >
            <!-- <b-button type="button" variant="danger" class="style_btn" @click="clickPdf()"><i class="far fa-file-pdf"></i> PDF</b-button> -->
          </div>
        </div>
        <div class="mb-4 custom-card">
          <detail-report
            :item="item"
            :funcs="funcs"
            :columnPeriod="columnPeriod"
            :columnTypeReport="columnTypeReport"
            :reportId="reportId"
            :data="information"
            :report="report"
          />
          <!-- <format-report  :reports="reports"
                          :report="report"
                          :funcs="funcs"/> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as scripts from "../../../util/scripts";
import mock from "../mock/report";
import formatReport from "./format.report";
import moment from "moment";
import pdfmake from "pdfmake";
import detailReport from "./detail.report";
import DateUtils from "../../../util/dateUtils";
import Constants from "../../../util/constants";
export default {
  name: "report-view-form-report",
  data() {
    return {
      permission: {
        appSlug: "report",
        actionData: {}
      },
      types: [],
      type_times: [],
      funcs: scripts.funcs,
      mock: mock,
      reports: [],
      show: false,
      report: {
        type_report: 1,
        type_time: 1,
        start: moment().toDate(),
        end: moment().toDate(),
        type_data: 1,
        data_current: 1,
        store_id: "",
        sale_id: "",
        type: 1
      },
      item: {
        rows: [],
        labels: [],
        data: [],
        type_report: 1,
        type_time: 1,
        type: ""
      },
      type_data: [],
      data_current: [],
      stores: [],
      sales: [],
      columns: [
        {
          label: this.tableHeader,
          field: "date"
        },
        {
          label: "จำนวน " + this.typeColumn,
          field: "amount"
        },
        {
          label: "รวมราคา",
          field: "total",
          dataFormat: this.priceFormat
        }
      ],
      data: [],
      columnPeriod: "วันที่",
      columnTypeReport: "(การขาย)",
      reportId: "",
      options: [
        { value: 1, text: "เวลา", disabled: false },
        { value: 2, text: "ตามข้อมูล", disabled: false },
        { value: 3, text: "ตามข้อมูลปัจจุบัน", disabled: true }
      ],
      information: {
        rows: [],
        labels: [],
        data: [],
        type_report: 1,
        type_data: 1,
        type: ""
      }
    };
  },
  computed: {
    filename() {
      let date = moment().format("DDMMYYYYHHmmss");
      console.log("typeColumn ", this.report.type_report);
      let wording = "sale";
      if (this.report.type_report == 1) {
        wording = "sale";
      } else if (this.report.type_report == 2) {
        wording = "delivery";
      } else if (this.report.type_report == 3) {
        wording = "payment";
      } else if (this.report.type_report == 4) {
        wording = "upsale";
      } else if (this.report.type_report == 5) {
        wording = "order";
      }
      return `${wording}-${date}`;
    },
    typeColumn() {
      console.log("typeColumn ", this.report.type_report);
      let wording = "(การขาย)";
      if (this.report.type_report == 1) {
        wording = "(การขาย)";
      } else if (this.report.type_report == 2) {
        wording = "(การจัดส่ง)";
      } else if (this.report.type_report == 3) {
        wording = "(การชำระเงิน)";
      } else if (this.report.type_report == 4) {
        wording = "(การอัพเซลล์)";
      } else if (this.report.type_report == 5) {
        wording = "(การสั่งซื้อ)";
      }
      return wording;
    },
    tableHeader() {
      let wording = "วันที่";
      if (this.report.type_time == 1) {
        wording = "วันที่";
      } else if (this.report.type_time == 2) {
        wording = "สัปดาห์";
      } else if (this.report.type_time == 3) {
        wording = "เดือน";
      } else if (this.report.type_time == 4) {
        wording = "ปี";
      } else if (this.report.type_time == 5) {
        wording = "ไตรมาส";
      }
      return wording;
    }
    // getLabelTypeReport () {
    //   let result = '';
    //   result = this.types.find((row) => {
    //     return row.id == this.report.type_report;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // },
    // getLabelTypeTime () {
    //   let result = '';
    //   result = this.type_times.find((row) => {
    //     return row.id == this.report.type_time;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // },
    // getLabelTypeData () {
    //   let result = '';
    //   result = this.type_data.find((row) => {
    //     return row.id == this.report.type_data;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // },
    // getLabelDataCurrent () {
    //   let result = '';
    //   result = this.data_current.find((row) => {
    //     return row.id == this.report.data_current;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // },
    // getLabelStore () {
    //   let result = '';
    //   result = this.stores.find((row) => {
    //     return row.id == this.report.store_id;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // },
    // getLabelSale () {
    //   let result = '';
    //   result = this.sales.find((row) => {
    //     return row.id == this.report.sale_id;
    //   });
    //   console.log('result ', result)
    //   return result ? result.name : '';
    // }
  },
  methods: {
    getDataTypes() {
      this.types = this.mock.types;
    },
    getDataTypeTime() {
      this.type_times = this.mock.type_times;
    },
    disableStartDate(date) {
      if (this.report.end) {
        return date > this.report.end;
      }
      return false;
    },
    disabledEndDate(date) {
      if (this.report.start) {
        return date < this.report.start;
      }
      return false;
    },
    async clickAgree() {
      let type_name = this.types.find(row => {
        return row.id == this.report.type_report;
      });
      let type_time_name = this.type_times.find(row => {
        return row.id == this.report.type_time;
      });

      this.show = true;
      this.report = {
        ...this.report,
        type_name: type_name.name,
        type_time_name: type_time_name.name
      };
      let dateFrom = this.report.start
        ? DateUtils.dateFormat(this.report.start, "YYYY-MM-DD")
        : "";
      let dateTo = this.report.end
        ? DateUtils.dateFormat(this.report.end, "YYYY-MM-DD")
        : "";
      let obj = {
        reportTypeName: type_name.name,
        subTypeName: this.options.find(v => v.value == this.report.type).text,
        dateFrom: dateFrom,
        dateTo: dateTo,
        typeReport: this.report.type_report
      };
      if (this.report.type_time) {
        let rows = this.mock.detail;

        let url = "";
        let params = "";
        let item = undefined;
        if (this.report.type_report == 1) {
          url = "/sale";
          let type = "";
          let subType = "";
          if (this.report.type == 1) {
            type = "time";
            if (this.report.type_time == 1) {
              subType = "day";
            } else if (this.report.type_time == 2) {
              subType = "week";
            } else if (this.report.type_time == 3) {
              subType = "month";
            } else if (this.report.type_time == 4) {
              subType = "year";
            } else if (this.report.type_time == 5) {
              subType = "other";
            }
            obj.typeName = this.type_times.find(
              v => v.id == this.report.type_time
            ).name;
          } else if (this.report.type == 2) {
            type = "data";
            if (this.report.type_data == 1) {
              subType = "customer";
            } else if (this.report.type_data == 2) {
              subType = "seller";
            } else if (this.report.type_data == 3) {
              subType = "product";
            } else if (this.report.type_data == 4) {
              subType = "store";
            } else if (this.report.type_data == 5) {
              subType = "supplier";
            }
            obj.typeName = this.type_data.find(
              v => v.id == this.report.type_data
            ).name;
          }

          params = `?type=${type}&subtype=${subType}&storeId=${this.report.store_id}&from=${dateFrom}&to=${dateTo}`;
          obj.storeId = this.report.store_id;
          obj.name = this.stores.find(v => v.id == this.report.store_id).text;
        } else if (this.report.type_report == 2) {
          url = "/delivery";
          let type = "";
          let subType = "";
          if (this.report.type == 1) {
            type = "time";
            if (this.report.type_time == 1) {
              subType = "day";
            } else if (this.report.type_time == 2) {
              subType = "week";
            } else if (this.report.type_time == 3) {
              subType = "month";
            } else if (this.report.type_time == 4) {
              subType = "year";
            } else if (this.report.type_time == 5) {
              subType = "other";
            }
          }

          params = `?type=${type}&subtype=${subType}&from=${dateFrom}&to=${dateTo}`;
          obj.typeName = this.type_times.find(
            v => v.id == this.report.type_time
          ).name;
        } else if (this.report.type_report == 3) {
          url = "/payment";
          let type = "";
          let subType = "";
          if (this.report.type == 1) {
            type = "time";
            if (this.report.type_time == 1) {
              subType = "day";
            } else if (this.report.type_time == 2) {
              subType = "week";
            } else if (this.report.type_time == 3) {
              subType = "month";
            } else if (this.report.type_time == 4) {
              subType = "year";
            } else if (this.report.type_time == 5) {
              subType = "other";
            }
            obj.typeName = this.type_times.find(
              v => v.id == this.report.type_time
            ).name;
          } else if (this.report.type == 3) {
            type = "";
            if (this.report.data_current == 1) {
              subType = "";
            }
            obj.typeName = this.data_current.find(
              v => v.id == this.report.data_current
            ).name;
          }

          params = `?type=${type}&subtype=${subType}&from=${dateFrom}&to=${dateTo}`;
        } else if (this.report.type_report == 4) {
          url = "/upSale";
          let type = "";
          let subType = "";
          if (this.report.type == 1) {
            type = "time";
            if (this.report.type_time == 1) {
              subType = "day";
            } else if (this.report.type_time == 2) {
              subType = "week";
            } else if (this.report.type_time == 3) {
              subType = "month";
            } else if (this.report.type_time == 4) {
              subType = "year";
            } else if (this.report.type_time == 5) {
              subType = "other";
            }
          }

          obj.sellerId = this.report.sale_id;
          params = `?type=${type}&subtype=${subType}&sellerId=${this.report.sale_id}&from=${dateFrom}&to=${dateTo}`;
          obj.typeName = this.type_times.find(
            v => v.id == this.report.type_time
          ).name;
          obj.name = this.sales.find(v => v.id == this.report.sale_id).text;
        } else if (this.report.type_report == 5) {
          url = "/AllSeller";
          let type = "";
          let subType = "";
          if (this.report.type == 1) {
            type = "time";
            if (this.report.type_time == 1) {
              subType = "day";
            } else if (this.report.type_time == 2) {
              subType = "week";
            } else if (this.report.type_time == 3) {
              subType = "month";
            } else if (this.report.type_time == 4) {
              subType = "year";
            } else if (this.report.type_time == 5) {
              subType = "other";
            }
          }

          params = `?type=${type}&subtype=${subType}&from=${dateFrom}&to=${dateTo}`;
          obj.typeName = this.type_times.find(
            v => v.id == this.report.type_time
          ).name;
        }
        if (url) {
          const result = await this.HttpServices.getMasterData(
            `/ab_report/report${url}${params}`
          );
          if (result && result.length > 0) {
            this.SessionStorageUtils.setSession(
              "report-data",
              JSON.stringify(obj)
            );
            item = rows.find(row => {
              if (this.report.type == 2) {
                return (
                  row.type_report == this.report.type_report &&
                  row.type == this.report.type &&
                  row.type_data == this.report.type_data
                );
              } else if (this.report.type == 3) {
                return (
                  row.type_report == this.report.type_report &&
                  row.type == this.report.type &&
                  row.data_current == this.report.data_current
                );
              } else {
                return (
                  row.type_report == this.report.type_report &&
                  row.type == this.report.type
                );
              }
            });
            if (!item) {
              item = {
                rows: [],
                labels: [],
                data: [],
                type_report: this.report.type_report,
                type_time: this.report.type_time,
                type: this.report.type
              };
            }
            const rowDatas = [];
            const data = [];
            const labels = [];

            console.log("this.report.type", this.report.type);
            console.log("this.report.type_report", this.report.type_report);
            console.log("this.report.type_data", this.report.type_data);

            if (this.report.type == 1) {
              if (this.report.type_report == 1) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.num,
                    date: v.moment,
                    total: v.total,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.moment);
                });
              } else if (this.report.type_report == 2) {
                result.forEach(v => {
                  let total = 0;
                  v.product_order.forEach(vv => {
                    total += vv.total;
                  });
                  rowDatas.push({
                    amount: v.price_shipping,
                    date: v.moment,
                    total: total,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.price_shipping);
                  labels.push(v.moment);
                });
              } else if (this.report.type_report == 3) {
                result.forEach(v => {
                  const momentDate = DateUtils.dateFormat(
                    DateUtils.toDateFormat(v.moment, "DD-MM-YYYY"),
                    "DD/MM/YYYY"
                  );
                  rowDatas.push({
                    amount: v.paymentUnit,
                    date: momentDate,
                    total: v.paid
                  });
                  data.push(v.paymentUnit);
                  labels.push(momentDate);
                });
              } else if (this.report.type_report == 4) {
                result.forEach(v => {
                  const momentDate = DateUtils.dateFormat(
                    DateUtils.toDateFormat(v.moment, "DD-MM-YYYY"),
                    "DD/MM/YYYY"
                  );
                  rowDatas.push({
                    amount: v.upsaleUnit,
                    date: momentDate,
                    total: v.total
                  });
                  data.push(v.upsaleUnit);
                  labels.push(momentDate);
                });
              } else if (this.report.type_report == 5) {
                // result.forEach(v=>{
                //   let total = 0;
                //   v.product_order.forEach(vv=>{
                //     total += vv.total;
                //   });
                //   rowDatas.push({amount: v.paymentUnit, date: v.moment, total: paid});
                //   data.push(v.paymentUnit);
                //   labels.push(v.moment);
                // });
                item = undefined;
              }
            } else if (this.report.type == 2) {
              if (this.report.type_report == 1 && this.report.type_data == 1) {
                const customerTypes = Constants.customer_types;
                const customerCredit = Constants.customer_credit_types;

                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unitOrder,
                    customer_name: v.name,
                    total: v.total,
                    customer_id: v.customer_id,
                    customer_type_name: customerTypes.find(
                      vv => vv.id == v.customer_type
                    ).text,
                    customer_status_name: customerCredit.find(
                      vv => vv.id == v.credit_status
                    ).text,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.name);
                });
              } else if (
                this.report.type_report == 1 &&
                this.report.type_data == 2
              ) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unit_orders,
                    customer_name: v.name,
                    total: v.total,
                    sale_id: v.seller_id,
                    sale_name: v.seller_name,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.seller_name);
                });
              } else if (
                this.report.type_report == 1 &&
                this.report.type_data == 3
              ) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unit_orders,
                    customer_name: v.name,
                    total: v.total,
                    product_code: v.product_code,
                    product_name: v.product_name,
                    product_type_name: v.category_name,
                    product_price: v.price,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.product_name);
                });
              } else if (
                this.report.type_report == 1 &&
                this.report.type_data == 4
              ) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unit_orders,
                    customer_name: v.name,
                    total: v.total,
                    store_id: v.store_id,
                    store_name: v.store_name,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.store_name);
                });
              } else if (
                this.report.type_report == 1 &&
                this.report.type_data == 5
              ) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unit_orders,
                    customer_name: v.name,
                    total: v.total,
                    supplier_id: v.supplier_id,
                    supplier_name: v.supplier_name,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.supplier_name);
                });
              } else if (
                this.report.type_report == 1 &&
                this.report.type_data == 6
              ) {
                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unit_orders,
                    customer_name: v.name,
                    total: v.total,
                    location_parent: v.location_parent,
                    location_type_name: v.location_type,
                    location_name: v.location_name,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.total);
                  labels.push(v.location_name);
                });
              } else {
                const customerTypes = Constants.customer_types;
                const customerCredit = Constants.customer_credit_types;

                result.forEach(v => {
                  rowDatas.push({
                    amount: v.unitOrder,
                    customer_name: v.name,
                    total: v.total,
                    customer_id: v.customer_id,
                    customer_type_name: customerTypes.find(
                      vv => vv.id == v.customer_type
                    ).text,
                    customer_status_name: customerCredit.find(
                      vv => vv.id == v.credit_status
                    ).text,
                    total_margin: v.total_margin,
                    average_price: v.average_price,
                    average_margin: v.average_margin,
                    margin: v.margin
                  });
                  data.push(v.unitOrder);
                  labels.push(v.name);
                });
              }
            } else if (this.report.type == 3) {
              const paymentStatus = Constants.payment_status;
              const paymentMethod = Constants.payment_methods;
              result.forEach(v => {
                let status = paymentStatus.find(vv => vv.id == v.status);
                status = status ? status.text : "";
                let methods = paymentMethod.find(vv => vv.id == v.methods);
                methods = methods ? methods.text : "";
                rowDatas.push({
                  amount: v.payUnit,
                  total: v.total,
                  payment_id: v.code,
                  customer_name: v.customer,
                  status_payment_name: status,
                  payment_type_name: methods
                });
                data.push(v.payUnit);
                labels.push(v.customer);
              });
              // code: "P-000000020"
              // customer: "คุณแอน"
              // methods: 1
              // moment: "11-11-2021"
              // payUnit: 0
              // status: 2
              // total: 0
            }
            item.data = data;
            item.labels = labels;
            item.rows = rowDatas;
          } else {
            item = undefined;
          }
        }
        // let item = rows.find((row) => {
        //   console.log('row ', row, this.report)
        //   if (this.report.type == 2) {
        //     console.log('report.type == 2')
        //     return row.type_report == this.report.type_report && row.type == this.report.type && row.type_data == this.report.type_data;
        //   } else if (this.report.type == 3) {
        //     console.log('report.type == 3')
        //     return row.type_report == this.report.type_report && row.type == this.report.type && row.data_current == this.report.data_current;
        //   } else {
        //     console.log('report.type == else')
        //     return row.type_report == this.report.type_report && row.type == this.report.type;
        //   }
        // });

        if (item === undefined) {
          this.item = {
            rows: [],
            labels: [],
            data: [],
            type_report: this.report.type_report,
            type_time: this.report.type_time,
            type: this.report.type
          };
        } else {
          this.item = item;
        }
        this.data = this.item.rows;

        if (this.report.type_report == 5) {
          this.columns = [
            {
              label: this.tableHeader,
              field: "date"
            },
            {
              label: "รหัสการสั่งซื้อ",
              field: "order_code"
            },
            {
              label: "ชื่อร้านค้า",
              field: "store_name"
            },
            {
              label: "สถานะสั่งซื้อ",
              field: "status_order_name"
            },
            {
              label: "สถานะการจ่าย",
              field: "status_payment_name"
            },
            {
              label: "สถานะจัดส่ง",
              field: "status_delivery_name"
            },
            {
              label: "จำนวน (การสั่งซื้อ)",
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type_report !== 5 && this.report.type == 1) {
          this.columns = [
            {
              label: this.tableHeader,
              field: "date"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 1) {
          this.columns = [
            {
              label: "รหัสลูกค้า",
              field: "customer_id"
            },
            {
              label: "ชื่อลูกค้า",
              field: "customer_name"
            },
            {
              label: "ประเภทลูกค้า",
              field: "customer_type_name"
            },
            {
              label: "สถานะเครดิตลูกค้า",
              field: "customer_status_name"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 2) {
          this.columns = [
            {
              label: "รหัสผู้ขาย",
              field: "sale_id"
            },
            {
              label: "ชื่อผู้ขาย",
              field: "sale_name"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 3) {
          this.columns = [
            {
              label: "รหัสสินค้า",
              field: "product_code"
            },
            {
              label: "ชื่อสินค้า",
              field: "product_name"
            },
            {
              label: "ประเภทสินค้า",
              field: "product_type_name"
            },
            {
              label: "ราคาสินค้า",
              field: "product_price"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 4) {
          this.columns = [
            {
              label: "รหัสร้านค้า",
              field: "store_id"
            },
            {
              label: "ชื่อร้านค้า",
              field: "store_name"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 5) {
          this.columns = [
            {
              label: "รหัสซัพพลายเออร์",
              field: "supplier_id"
            },
            {
              label: "ชื่อซัพพลายเออร์",
              field: "supplier_name"
            },
            {
              label: "เกรด",
              field: "supplier_tier"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 2 && this.report.type_data == 6) {
          this.columns = [
            {
              label: "รหัสสถานที่ตั้ง",
              field: "location_id"
            },
            {
              label: "ชื่อสถานที่ตั้ง",
              field: "location_name"
            },
            {
              label: "ประเภทสถานที่ตั้ง",
              field: "location_type_name"
            },
            {
              label: "สถานที่ตั้งหลัก",
              field: "location_parent"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            },
            {
              label: "Total Margin",
              field: "total_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Price",
              field: "average_price",
              dataFormat: this.priceFormat
            },
            {
              label: "Average Margin",
              field: "average_margin",
              dataFormat: this.priceFormat
            },
            {
              label: "% Margin",
              field: "margin",
              dataFormat: this.priceFormat
            }
          ];
        } else if (this.report.type == 3 && this.report.data_current == 1) {
          this.columns = [
            {
              label: "รหัสการชำระเงิน",
              field: "payment_id"
            },
            {
              label: "ประเภทการชำระเงิน",
              field: "payment_type_name"
            },
            {
              label: "จำนวน " + this.typeColumn,
              field: "amount"
            },
            {
              label: "รวมราคา",
              field: "total",
              dataFormat: this.priceFormat
            }
          ];
        }
      }
      this.columnPeriod = this.tableHeader;
      this.columnTypeReport = this.typeColumn;
      this.reportId = type_name.id;
    },
    clickClear() {
      this.report = {
        type_report: 1,
        type_time: 1,
        start: "",
        end: ""
      };
      this.show = false;
    },
    clickPdf() {
      var docDefinition = {
        content: [
          {
            layout: "lightHorizontalLines", // optional
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ["*", "auto", 100, "*"],

              body: [
                ["First", "Second", "Third", "The last one"],
                ["Value 1", "Value 2", "Value 3", "Value 4"],
                [{ text: "Bold value", bold: true }, "Val 2", "Val 3", "Val 4"]
              ]
            }
          }
        ]
      };
      pdfMake.tableLayouts = {
        exampleLayout: {
          hLineWidth: function(i, node) {
            if (i === 0 || i === node.table.body.length) {
              return 0;
            }
            return i === node.table.headerRows ? 2 : 1;
          },
          vLineWidth: function(i) {
            return 0;
          },
          hLineColor: function(i) {
            return i === 1 ? "black" : "#aaa";
          },
          paddingLeft: function(i) {
            return i === 0 ? 0 : 8;
          },
          paddingRight: function(i, node) {
            return i === node.table.widths.length - 1 ? 0 : 8;
          }
        }
      };

      // download the PDF
      pdfMake.createPdf(docDefinition).download();
    },
    changeTypeTime(status) {
      this.report.type_time = status;
    },
    changeTypeData(data) {
      this.report.type_data = data;
    },
    getTypeData() {
      this.type_data = this.mock.type_data;
    },
    getDataCurrent() {
      this.data_current = this.mock.data_current;
    },
    changeDataCurrent(data) {
      this.report.data_current = data;
    },
    changeTypeReport(type_report) {
      if (type_report == 1) {
        this.report.type_data = 1;
        this.report.data_current = "";
        this.options[0].disabled = false;
        this.options[1].disabled = false;
        this.options[2].disabled = true;
        this.report.type = 1;
      } else if (type_report == 2) {
        this.report.data_current = "";
        this.report.type_data = 1;
        this.options[0].disabled = false;
        this.options[1].disabled = true;
        this.options[2].disabled = true;
        this.report.type = 1;
      } else if (type_report == 3) {
        this.report.data_current = 1;
        this.report.type_data = "";
        this.options[0].disabled = false;
        this.options[1].disabled = true;
        this.options[2].disabled = false;
        this.report.type = 1;
      } else if (type_report == 4) {
        this.report.data_current = "";
        this.report.type_data = 1;
        this.options[0].disabled = false;
        this.options[1].disabled = true;
        this.options[2].disabled = true;
        this.report.type = 1;
      } else if (type_report == 5) {
        this.report.data_current = "";
        this.report.type_data = "";
        this.options[0].disabled = true;
        this.options[1].disabled = true;
        this.options[2].disabled = true;
        this.report.type = "";
      }
    },
    changeStore(data) {
      this.report.store_id = data;
    },
    changeSale(data) {
      this.report.sale_id = data;
    },
    async getStores() {
      const result = await this.HttpServices.getMasterData(`/master/getStores`);
      this.stores = result;
      if (this.stores.length > 0) {
        this.report.store_id = this.stores[0].id;
      }
    },
    async getSales() {
      const result = await this.HttpServices.getMasterData(`/master/getUsers`);
      this.sales = result;
      if (this.sales.length > 0) {
        this.report.sale_id = this.sales[0].id;
      }
    },
    priceFormat(value) {
      return this.funcs.toDecimalFormat(value);
    },
    clickPrint() {
      window.print();
    }
  },
  components: {
    formatReport,
    detailReport
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.SessionStorageUtils.deleteSession("report-data");
    this.getDataTypes();
    this.getDataTypeTime();
    this.getTypeData();
    this.getDataCurrent();
    this.getStores();
    this.getSales();
  }
};
</script>
<style scoped></style>
