<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {};
  },
  props: [
    'item'
  ],
  mounted() {
    console.log('area', this.item.data)
    this.renderChart({
      labels: this.item.labels,
      datasets: [
        {
          label: 'รวมราคา',
          backgroundColor: '#D4E6F1',
          data: this.item.data,
          borderColor: "#5DADE2",
        }
      ]
    }, { responsive: true, maintainAspectRatio: false });
  },
  watch: {
    'item.rows' (newVal, oldVal) {
      console.log('item.rows ', newVal)
      this.renderChart({
        labels: this.item.labels,
        datasets: [
          {
            label: 'รวมราคา',
            backgroundColor: '#D4E6F1',
            data: this.item.data,
            borderColor: "#5DADE2",
          }
        ]
      }, { responsive: true, maintainAspectRatio: false });
    }
  }
};
</script>
