export default {
  types: [{
    id: 1,
    name: 'รายงานการขาย'
  }, {
    id: 2,
    name: 'รายงานการชำระเงิน'
  }, {
    id: 3,
    name: 'ข้อมูลรายงานรายการสั่งซื้อ'
  }, {
    id: 4,
    name: 'รายงานยอดบริจาค (TBC)'
  }],
  type_times: [{
    id: 1,
    name: 'รายวัน'
  }, {
    id: 2,
    name: 'รายอาทิตย์'
  }, {
    id: 3,
    name: 'รายเดือน'
  }, {
    id: 4,
    name: 'รายปี'
  }, {
    id: 5,
    name: 'ไตรมาส'
  }],
  reports: [{
    id: 1,
    name: 'สั่งดอกไม้',
    price: 1500,
    amount: 150
  }, {
    id: 2,
    name: 'สั่งพวงหวีด',
    price: 1500,
    amount: 150
  }],
  detail: [{
    type_report: 1,
    type_report_name: 'รายงานการขาย',
    type_time: 1,
    type_time_name: 'รายวัน',
    type_data: 1,
    type_data_name: 'ลูกค้า',
    data_current: '',
    data_current_name: '',
    store_id: 1,
    store_name: 'หรีด ณ วัด',
    order_code: '',
    start: '12/04/2020',
    end: '15/09/2020',
    sale_id: 1,
    sale_name: 'Gee',
    labels: ['03/2020', '04/2020', '05/2020', '06/2020', '07/2020'],
    data: [300, 300, 600, 150, 150],
    type: 1,
    type_name: 'เวลา',
    rows: [{
      date: '05/03/2020',
      amount: 2,
      total: 300,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '06/03/2020',
      amount: 2,
      total: 300,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '21/03/2020',
      amount: 3,
      total: 600,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '21/07/2020',
      amount: 1,
      total: 150,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '30/07/2020',
      amount: 1,
      total: 150,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }]
  }, {
    type_report: 2,
    type_report_name: 'รายงานการจัดส่ง',
    type_time: 2,
    type_time_name: 'รายอาทิตย์',
    type_data: '',
    type_data_name: '',
    data_current: '',
    data_current_name: '',
    store_id: '',
    store_name: '',
    order_code: '',
    start: '12/04/2020',
    end: '15/09/2020',
    sale_id: 1,
    sale_name: 'Gee',
    labels: ['03/2020', '04/2020', '05/2020', '06/2020', '07/2020'],
    data: [300, 300, 600, 150, 150],
    type: 1,
    type_name: 'เวลา',
    rows: [{
      date: '03/2020',
      amount: 2,
      total: 300,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '03/2020',
      amount: 2,
      total: 300,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '03/2020',
      amount: 3,
      total: 600,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '07/2020',
      amount: 1,
      total: 150,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '07/2020',
      amount: 1,
      total: 150,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: '',
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }]
  }, {
    type_report: 5,
    type_report_name: 'รายงานการสั่งซื้อ',
    type_time: '',
    type_time_name: '',
    type_data: '',
    type_data_name: '',
    data_current: 1,
    data_current_name: '',
    store_id: 1,
    store_name: 'กุหลาบ',
    order_code: 'O-0001',
    start: '12/04/2020',
    end: '15/09/2020',
    sale_id: 1,
    sale_name: 'Gee',
    labels: ['05/03/2020', '06/03/2020', '21/03/2020', '21/07/2020', '30/07/2020'],
    data: [300, 300, 600, 150, 150],
    type: 1,
    type_name: 'เวลา',
    rows: [{
      date: '05/03/2020',
      amount: 2,
      total: 300,
      order_code: 'O-0001',
      store_id: 1,
      store_name: 'กุหลาบ',
      status_order: 1,
      status_order_name: 'ยืนยันการขาย',
      status_payment: 1,
      status_payment_name: 'รอชำระเงิน',
      status_delivery: 1,
      status_delivery_name: 'รอรูปจัดเสร็จ',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '06/03/2020',
      amount: 2,
      total: 300,
      order_code: 'O-0001',
      store_id: 1,
      store_name: 'กุหลาบ',
      status_order: 2,
      status_order_name: 'Approved',
      status_payment: 2,
      status_payment_name: 'ชำระเงินแล้ว',
      status_delivery: 2,
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '21/03/2020',
      amount: 3,
      total: 600,
      order_code: 'O-0001',
      store_id: 1,
      store_name: 'กุหลาบ',
      status_order: 3,
      status_order_name: 'ส่งรายการให้ร้านค้า',
      status_payment: 2,
      status_payment_name: 'ชำระเงินแล้ว',
      status_delivery: 3,
      status_delivery_name: 'รอรูปผู้รับ',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '21/07/2020',
      amount: 1,
      total: 150,
      order_code: 'O-0001',
      store_id: 1,
      store_name: 'กุหลาบ',
      status_order: 4,
      status_order_name: 'ปิดการขายเรียบร้อย',
      status_payment: 3,
      status_payment_name: 'ยืนยันการชำระเงินแล้ว',
      status_delivery: 4,
      status_delivery_name: 'ส่งรูปผู้รับแล้ว',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '30/07/2020',
      amount: 1,
      total: 150,
      order_code: 'O-0001',
      store_id: 1,
      store_name: 'กุหลาบ',
      status_order: 5,
      status_order_name: 'พบปัญหารอการแก้ไข',
      status_payment: 1,
      status_payment_name: 'รอชำระเงิน',
      status_delivery: 2,
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว',
      customer_id: '',
      customer_name: '',
      customer_type_id: '',
      customer_type_name: '',
      customer_status_id: '',
      customer_status_name: '',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }]
  }, {
    type_report: 1,
    type_report_name: 'รายงานการขาย',
    type_time: '',
    type_time_name: '',
    type_data: 1,
    type_data_name: 'ลูกค้า',
    data_current: '',
    data_current_name: '',
    store_id: 1,
    store_name: 'หรีด ณ วัด',
    order_code: '',
    start: '12/04/2020',
    end: '15/09/2020',
    sale_id: '',
    sale_name: '',
    labels: ['สง่า', 'สมบัติ', 'สะอาด', 'สมพิศ', 'เครือ'],
    data: [1500, 800, 545, 1100, 1250],
    type: 2,
    type_name: 'ตามข้อมูล',
    rows: [{
      date: '',
      amount: 3,
      total: 1500,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: 1,
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: 1,
      customer_name: 'สง่า',
      customer_type_id: 1,
      customer_type_name: 'นิติบุคคล',
      customer_status_id: 0,
      customer_status_name: 'ไม่มี',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '',
      amount: 2,
      total: 800,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: 1,
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: 3,
      customer_name: 'สมบัติ',
      customer_type_id: 1,
      customer_type_name: 'นิติบุคคล',
      customer_status_id: 1,
      customer_status_name: 'มี',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '',
      amount: 2,
      total: 545,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: 1,
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: 5,
      customer_name: 'สะอาด',
      customer_type_id: 2,
      customer_type_name: 'บุคคลธรรมดา',
      customer_status_id: 0,
      customer_status_name: 'ไม่มี',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '',
      amount: 2,
      total: 1100,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: 1,
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: 11,
      customer_name: 'สมพิศ',
      customer_type_id: 2,
      customer_type_name: 'บุคคลธรรมดา',
      customer_status_id: 1,
      customer_status_name: 'มี',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }, {
      date: '',
      amount: 2,
      total: 1250,
      order_code: '',
      store_id: '',
      store_name: '',
      status_order: '',
      status_order_name: '',
      status_payment: 1,
      status_payment_name: '',
      status_delivery: '',
      status_delivery_name: '',
      customer_id: 12,
      customer_name: 'เครือ',
      customer_type_id: 1,
      customer_type_name: 'นิติบุคคล',
      customer_status_id: 0,
      customer_status_name: 'ไม่มี',
      sale_id: '',
      sale_name: '',
      product_code: '',
      product_name: '',
      product_type_id: '',
      product_type_name: '',
      product_price: '',
      supplier_id: '',
      supplier_name: '',
      supplier_tier: '',
      location_id: '',
      location_name: '',
      location_type_id: '',
      location_type_name: '',
      location_parent: '',
      payment_id: '',
      payment_type: '',
      payment_type_name: ''
    }]
  }],
  type_data: [{
    id: 1,
    name: 'ลูกค้า'
  }, {
    id: 2,
    name: 'ผู้ขาย'
  }, {
    id: 3,
    name: 'สินค้า'
  }, {
    id: 4,
    name: 'ร้านค้า'
  }, {
    id: 5,
    name: 'ตามซัพพลายเออร์'
  }, {
    id: 6,
    name: 'ตามสถานที่ตั้ง'
  }],
  data_current: [{
    id: 1,
    name: 'เหลือ ค้างเท่าไหร่'
  }],
  stores: [{
    id: 1,
    name: 'หรีด ณ วัด'
  }, {
    id: 2,
    name: 'หรีดมาลา'
  }, {
    id: 3,
    name: 'หรีดสเตชั่น'
  }],
  sales: [{
    id: 1,
    name: 'จำปี'
  }, {
    id: 2,
    name: 'จำปา'
  }, {
    id: 3,
    name: 'จำเป็น'
  }],
  count_detail: {
    type_report: 1,
    type_report_name: 'รายงานการขาย',
    type_time: 1,
    type_time_name: 'รายวัน',
    type_data:'',
    type_data_name: '',
    data_current: '',
    data_current_name: '',
    start: '12/02/2020',
    end: '20/05/2021',
    store_id: 1,
    store_name: 'หรีด ณ วัด',
    sale_id: '',
    sale_name: '',
    order_order: '',
    type: 1,
    type_name: 'เวลา',
    products: [{
      id: 1,
      code: 'PD-0001',
      name: 'ช่อกุหลาบ',
      cost: 300,
      price: 400,
      count: 1
    }, {
      id: 1,
      code: 'PD-0002',
      name: 'ช่อลิลลี่',
      cost: 300,
      price: 400,
      count: 1
    }]
  },
  count_detail_customer: {
    type_report: 1,
    type_report_name: 'รายงานการขาย',
    type_time: '',
    type_time_name: '',
    type_data: 2,
    type_data_name: 'ลูกค้า',
    data_current: '',
    data_current_name: '',
    start: '12/02/2020',
    end: '20/05/2021',
    store_id: '',
    store_name: '',
    sale_id: '',
    sale_name: '',
    order_order: '',
    type: 2,
    type_name: 'ตามข้อมูล',
    customer_id: 1,
    customer_name: 'สง่า',
    rows: [{
      id: 1,
      order_code: 'PD-0001',
      order_date: '12/12/2020',
      amount: 500,
      count_product: 2,
      status_order: 1,
      status_order_name: 'ยืนยันการขาย',
      status_payment: 1,
      status_payment_name: 'รอชำระเงิน',
      status_delivery: 2,
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว'
    }, {
      id: 1,
      order_code: 'PD-0002',
      order_date: '12/12/2020',
      amount: 500,
      count_product: 1,
      status_order: 1,
      status_order_name: 'ยืนยันการขาย',
      status_payment: 1,
      status_payment_name: 'รอชำระเงิน',
      status_delivery: 2,
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว'
    }, {
      id: 1,
      order_code: 'PD-0002',
      order_date: '12/12/2020',
      amount: 500,
      count_product: 1,
      status_order: 1,
      status_order_name: 'ยืนยันการขาย',
      status_payment: 1,
      status_payment_name: 'รอชำระเงิน',
      status_delivery: 2,
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว'
    }]
  }
}
