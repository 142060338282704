<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-file-alt"></i> รายงาน </h2>
            </b-col>
          </b-row>
        </div>
        <page-report  :apiUrl="apiUrl" />
      </div>
    </div>
  </div>
</template>
<script>

  import pageReport from "./view/page.report.vue";
  import * as scripts from '../../util/scripts';
  import config from '../../util/config';

  export default {
    name: 'report-index',
    data() {
      return {
        permission:{
          appSlug: 'report'
        },
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0
        },
        funcs: scripts.funcs,
        apiUrl: config.apiUrl
      }
    },
    methods: {},
    components: {
      pageReport
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    },
  };
</script>
<style>

</style>
