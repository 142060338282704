
      
<template>
  <div class="table-responsive padding_top_20">
    <table class="table align-middle table-bordered">
      <thead>
        <tr class="text-center" >
          <th>รหัสการชำระเงิน</th>
          <th>ลูกค้า</th>
          <th>สถานะการชำระเงิน</th>
          <th>รูปแบบการจ่ายเงิน</th>
          <th>จำนวน {{columnTypeReport}}</th>
          <th>รวมราคา</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in item.rows" :key="index"
            :id="`report_order${index}`">
          <td class="text-center">{{data.payment_id}}</td>
          <td class="text-center">{{data.customer_name}}</td>
          <td class="text-center">{{data.status_payment_name}}</td>
          <td class="text-center">{{data.payment_type_name}}</td>
          <td class="text-center">
            <router-link :to="`/report/count_detail`" class="nav-link text-underline">{{data.amount}}</router-link>
          </td>
          <td class="text-right">{{funcs.toDecimalFormat(data.total)}}</td>
        </tr>
        <tr v-if="item.rows.length > 0" class="text-center">
          <td><strong>จำนวนทั้งหมด</strong></td>
          <td colspan="7" class="text-right">
            <strong>{{funcs.toDecimalFormat(amount)}}</strong>
          </td>
        </tr>
        <tr v-if="item.rows.length > 0" class="text-center">
          <td><strong>รวมทั้งสิ้น</strong></td>
          <td colspan="7" class="text-right">
            <strong>{{funcs.toDecimalFormat(total)}}</strong>
          </td>
        </tr>
        <tr v-if="item.rows.length === 0" class="text-center">
          <td colspan="6">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    name: 'report-view-table-dataCurrent-payment',
    props: [
      'item',
      'funcs',
      'columnTypeReport'
    ],
    computed: {
      amount () {
        let result = '';
        result = this.item.rows.reduce((sum, row) => {
          if (row.amount) {
            return sum + +row.amount;
          } else {
            return sum + 0;
          }
        }, 0)
        return result;
      },
      total () {
        let result = '';
        result = this.item.rows.reduce((sum, row) => {
          if (row.total) {
            return sum + +row.total;
          } else {
            return sum + 0;
          }
        }, 0)
        return result;
      }
    }
  };
</script>

