<template lang="">
  <div>
    <form-report  :apiUrl="apiUrl"/>
  </div>
</template>
<script>
import formReport from './form.report';
export default {
  name: 'report-view-page-report',
  data () {
    return {}
  },
  props: [
    'apiUrl'
  ],
  methods: {},
  components: {
    formReport
  }
}
</script>
