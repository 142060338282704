<template>
  <div class="row  p-6">
    <div class="col-12">
      <address>
        <strong>ประเภทรายงาน : </strong> {{report.type_name}}<br>
        <strong>แบ่งข้อมูลตามประเภทเวลา : </strong> {{report.type_time_name}}<br>
        <strong>ตั้งแต่วันที่ :</strong> {{date}}
      </address>
      <div class="table-responsive padding_top_20">
        <table class="table align-middle table-bordered">
          <thead>
            <tr class="text-center" >
              <th>รายการ</th>
              <th>ราคา</th>
              <th>จำนวน</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data) in reports"
                :key="data.id"
                v-if="reports.length > 0">
              <td class= "text-center">
                {{data.name}}
              </td>
              <td class="text-right">{{funcs.toDecimalFormat(data.price)}}</td>
              <td class="text-center">{{data.amount}}</td>
            </tr>
            <tr v-if="reports.length === 0" class="text-center">
              <td colspan="3">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import * as scripts from '../../../util/scripts';
  import config from '../../../util/config';
  import mock from '../mock/report';
  import $ from 'jquery';
  import moment from 'moment';
  export default {
    name: 'report-view-format-report',
    props: [
      'reports',
      'report',
      'funcs'
    ],
    computed: {
      date () {
        if (this.report.start && this.report.end) {
          return moment(this.report.start).format('DD/MM/YYYY') + ' - ' + moment(this.report.end).format('DD/MM/YYYY');
        }
      }
    }
  };
</script>

<style scoped>
  .table-plain tbody tr,
  .table-plain tbody tr:hover,
  .table-plain tbody td {
    background-color:transparent;
    border:none;
  }
</style>
